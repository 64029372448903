export const HOST_URL = 'https://service.rizanova.uz/v1';
// export const HOST_URL = 'http://rizanova-media.loc/v1';
export const FILE_HOST_URL = "https://files.rizanova.uz";
export const SERVICE_HOST_URL = "https://control.rizanova.uz";

export const PROFA_ARTIST = 1;
export const PROFA_AKTYOR = 2;
export const PROFA_REJISYOR = 3;
export const PROFA_PRODYUSER = 4;
export const PROFA_SCENARIY = 5;
export const PROFA_OPERATOR = 6;
export const PROFA_KOMPOZITOR = 7;
export const PROFA_XUDOJNIK = 8;
export const PROFA_MONTAJ = 9;
export const PROFA_AVTOR_TEKSTA = 10;
export const PROFA_LEYBL = 11;
export const PROFA_STUDIO = 12;