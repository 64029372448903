import React, { useRef, useState } from 'react';
import { NavLink, useHistory, useParams } from 'react-router-dom';
import { AuthContext } from '../../components/context';
import { useForm, Controller } from "react-hook-form";
import AsyncSelect from 'react-select/async';
import Select from 'react-select';
import { arrayToFormData, useIsMounted } from '../../utils/functions';
import { toast } from 'react-toastify';
import { get, post } from '../../utils/request';
import Dropzone from 'react-dropzone';
import { PROFA_AKTYOR, PROFA_ARTIST, PROFA_KOMPOZITOR, PROFA_LEYBL, PROFA_MONTAJ, PROFA_OPERATOR, PROFA_PRODYUSER, PROFA_REJISYOR, PROFA_SCENARIY, PROFA_XUDOJNIK, PROFA_STUDIO } from '../../config';
import UploadEposide from '../../components/UploadEpisode';
import ExistingEpisode from '../../components/ExistingEpisode';
import AsyncCreatableSelect from '../../components/AsyncCreatableSelect';
import moment from 'moment';

const controllerName = "serial";
const title = "Сериалы";

let statuses = [
    { value: "1", label: 'Доступен всем' },
    { value: "0", label: 'Ограничить доступ' },
    { value: "2", label: 'Доступ по ссылке' },
    { value: "12", label: 'Модерация' },
    { value: "15", label: 'Отклонен' },
];

export default function SerialHandle() {
    const authContext = React.useContext(AuthContext);

    let history = useHistory();

    const [isLoading, setIsLoading] = useState(false);
    const [model, setModel] = useState(null);

    const isMounted = useIsMounted();

    const { id } = useParams();

    const imageRef = useRef();
    const portraitImageRef = useRef();
    const bannerImageRef = useRef();
    const pageImageRef = useRef();

    let [previewBannerImage, setPreviewBannerImage] = useState(null);
    let [previewPageImage, setPreviewPageImage] = useState(null);
    let [previewImage, setPreviewImage] = useState(null);
    let [previewPortraitImage, setPreviewPortraitImage] = useState(null);

    const [episodes, setEpisodes] = useState([]);

    const defaultValues = {
        name: "",
        subtitle_uz: "",
        subtitle_ru: "",
        filename: "",
        release_date: moment().format("YYYY-MM-DD"),
        release_time: "11:00",
        status: statuses[0],

        duration: "",
        is_premiere: false,
        is_home: false,
        is_page: false,
        producers: [],
        studios: [],
        operators: [],
        compositors: [],
        leybls: [],
        actors: [],
        directors: [],
        scenarists: [],
        xudojniks: [],
        montajs: [],
        genres: [],
        countries: [],
    }

    const { register, handleSubmit, formState: { errors }, control, reset, setValue, watch, trigger } = useForm({
        defaultValues
    });

    let watchIsHome = watch("is_home");
    let watchIsPage = watch("is_page");

    const fetchData = async () => {
        setIsLoading(true);

        let res = await get(`${controllerName}/get?id=${id}`);

        if (isMounted) {
            setModel(res);

            setIsLoading(false);
        }
    }

    React.useEffect(() => {
        document.title = title;

        if (id) {
            fetchData();
        }
    }, [id]);

    React.useEffect(() => {
        if (model) {
            let dates = model.release_date ? model.release_date.split(" ") : [];

            let release_date = dates.length > 0 ? dates[0] : "";
            let release_time = dates.length > 0 ? dates[1] : "";

            reset({
                name: model.name,
                subtitle_uz: model.subtitle_uz,
                subtitle_ru: model.subtitle_ru,
                duration: model.duration,
                is_premiere: model.is_premiere,
                is_home: model.is_home,
                is_page: model.is_page,

                compositors: [...authContext.personState.filter((person) => model.compositors.indexOf(person.value) > -1)].sort((a, b) => model.compositors.indexOf(a.value) - model.compositors.indexOf(b.value)),
                studios: [...authContext.personState.filter((person) => model.studios.indexOf(person.value) > -1)].sort((a, b) => model.studios.indexOf(a.value) - model.studios.indexOf(b.value)),
                producers: [...authContext.personState.filter((person) => model.producers.indexOf(person.value) > -1)].sort((a, b) => model.producers.indexOf(a.value) - model.producers.indexOf(b.value)),
                actors: [...authContext.personState.filter((person) => model.actors.indexOf(person.value) > -1)].sort((a, b) => model.actors.indexOf(a.value) - model.actors.indexOf(b.value)),
                leybls: [...authContext.personState.filter((person) => model.leybls.indexOf(person.value) > -1)].sort((a, b) => model.leybls.indexOf(a.value) - model.leybls.indexOf(b.value)),
                directors: [...authContext.personState.filter((person) => model.directors.indexOf(person.value) > -1)].sort((a, b) => model.directors.indexOf(a.value) - model.directors.indexOf(b.value)),
                scenarists: [...authContext.personState.filter((person) => model.scenarists.indexOf(person.value) > -1)].sort((a, b) => model.scenarists.indexOf(a.value) - model.scenarists.indexOf(b.value)),
                operators: [...authContext.personState.filter((person) => model.operators.indexOf(person.value) > -1)].sort((a, b) => model.operators.indexOf(a.value) - model.operators.indexOf(b.value)),
                montajs: [...authContext.personState.filter((person) => model.montajs.indexOf(person.value) > -1)].sort((a, b) => model.montajs.indexOf(a.value) - model.montajs.indexOf(b.value)),
                xudojniks: [...authContext.personState.filter((person) => model.xudojniks.indexOf(person.value) > -1)].sort((a, b) => model.xudojniks.indexOf(a.value) - model.xudojniks.indexOf(b.value)),
                genres: [...authContext.genreState.filter((genre) => model.genres.indexOf(genre.value) > -1)],
                countries: [...authContext.countryState.filter((country) => model.countries.indexOf(country.value) > -1)],
                status: statuses.find((status) => status.value == model.status),
                release_date: release_date,
                release_time: release_time
            });
        }
    }, [model]);

    const onSubmit = async (data) => {
        if (isLoading) return;

        setIsLoading(true);

        let res = await post(`${controllerName}/create${id ? `?id=${id}` : ''}`, arrayToFormData({
            ...data,
            file: data.file ? data.file : '',
            portrait_file: data.portrait_file ? data.portrait_file : '',
            banner_file: data.banner_file ? data.banner_file : '',
            page_file: data.page_file ? data.page_file : '',
            compositors: data.compositors.map((model) => model.value),
            producers: data.producers.map((model) => model.value),
            operators: data.operators.map((model) => model.value),
            montajs: data.montajs.map((model) => model.value),
            xudojniks: data.xudojniks.map((model) => model.value),
            countries: data.countries.map((model) => model.value),
            genres: data.genres.map((model) => model.value),
            studios: data.studios.map((model) => model.value),
            actors: data.actors.map((model) => model.value),
            scenarists: data.scenarists.map((model) => model.value),
            leybls: data.leybls.map((model) => model.value),
            directors: data.directors.map((model) => model.value),
            status: data.status ? data.status.value : 0,
            is_premiere: data.is_premiere ? 1 : 0,
            is_home: data.is_home ? 1 : 0,
            is_page: data.is_page ? 1 : 0,
            release_date: data.release_time ? data.release_date + " " + data.release_time : data.release_date
        }));

        if (res?.status === "success") {
            if (isMounted) {
                window.onbeforeunload = function (e) {
                    return null;
                };

                if (!id) {
                    history.push(`/${controllerName}/` + res.model.id)
                }
                else setModel(res.model);

                authContext.loadSerials();
                toast.success(res.message);
            }
        }

        if (isMounted) {
            setIsLoading(false);
        }
    }

    const loadPersons = (profa = PROFA_ARTIST) => (inputValue, callback) => {
        if (callback)
            callback(authContext.personState.sort((a, b) => a.label.localeCompare(b.label)).filter(person => person.model.profas.indexOf(profa) > -1).filter((model) => model.label.toLowerCase().indexOf(inputValue.toLowerCase()) > -1));
    }
    const loadGenres = (inputValue, callback) => {
        if (callback)
            callback(authContext.genreState.filter((model) => model.label.toLowerCase().indexOf(inputValue.toLowerCase()) > -1));
    }

    const loadCountries = (inputValue, callback) => {
        if (callback)
            callback(authContext.countryState.filter((model) => model.label.toLowerCase().indexOf(inputValue.toLowerCase()) > -1));
    }

    const deleteThis = (name) => {
        setEpisodes(oldEpisodes => oldEpisodes.filter(episode => episode.name != name));
    }

    return (
        <section className="categories py-70 bg-different">
            <div className="container-fluid">
                <div className="row justify-content-center">
                    <div className="col-md-10 my-container">
                        <nav className="title-nav mb-2 flex-wrap">
                            <div className="d-flex align-content-center align-items-end mb-3 col-12 col-md-4">
                                <h3 className="title-1 mt-0 mb-0 d-flex">
                                    <span className="me-3">
                                        {title}
                                    </span>

                                </h3>
                                <NavLink to={`/${controllerName}`} className="show-all btn btn-secondary fs-12 me-3">
                                    <span>
                                        Назад
                                    </span>
                                    <i className="fa fa-angle-left"></i>
                                </NavLink>
                            </div>
                        </nav>

                        <div className="row">
                            <div className="col-lg-6">
                                <div className="d-flex mb-2 w-100">
                                    <div className="text-center me-3">
                                        <div className={"uploaded-image border-radius-5 " + (errors.file?.type == "required" ? "required" : "")}>
                                            {previewImage ? <img className="w-100 d-block" src={previewImage} /> : (model && model.image ? <a href={model.image} className="w-100" target="_blank"><img className="w-100 d-block" src={model.image} /> </a> : <p className="text-white w-100 my-0">Картинка</p>)}
                                        </div>
                                        <a role="button" className="text-white mt-2 d-inline-block" onClick={() => {
                                            imageRef.current.click();
                                        }}>{previewImage ? "Изменить" : "Загрузить"}</a>
                                    </div>
                                    <div className="text-center me-3">
                                        <div className={"uploaded-image-portrait border-radius-5 " + (errors.portrait_file?.type == "required" ? "required" : "")}>
                                            {previewPortraitImage ? <img className="w-100 d-block" src={previewPortraitImage} /> : (model && model.portrait_image ? <a href={model.portrait_image} className="w-100" target="_blank"><img className="w-100 d-block" src={model.portrait_image} /> </a> : <p className="text-white w-100 my-0">Картинка</p>)}
                                        </div>
                                        <a role="button" className="text-white mt-2 d-inline-block" onClick={() => {
                                            portraitImageRef.current.click();
                                        }}>{previewPortraitImage ? "Изменить" : "Загрузить"}</a>
                                    </div>

                                    <div className="col me-3">
                                        <div className="formColRowFlexDiv">
                                            <Controller
                                                control={control}
                                                rules={{ required: true }}
                                                name="status"
                                                render={({ field }) => (
                                                    <Select
                                                        {...field}
                                                        placeholder="Параметы доступа"
                                                        className="basic-single w-100"
                                                        classNamePrefix="select"
                                                        defaultValue={{ value: "1", label: 'Доступен всем' }}
                                                        isClearable={false}
                                                        isSearchable={false}
                                                        options={statuses}
                                                    />
                                                )}
                                            />
                                        </div>
                                    </div>

                                    <div className="col">
                                        <div className="formColRowFlexDiv">
                                            <input type="checkbox" className="btn-check" id="is_premiere" autoComplete="off" {...register("is_premiere")} />
                                            <label className="btn btn-outline-success w-100 premiere" htmlFor="is_premiere">Премьера</label>
                                        </div>

                                        {model && model.premiere_left && <div className="formColRowFlexDiv text-white">
                                            Осталось {model.premiere_left}
                                        </div>}

                                        <div className="formColRowFlexDiv">
                                            <input type="checkbox" className="btn-check" id="is_home" autoComplete="off" {...register("is_home")} />
                                            <label className="btn btn-outline-success w-100 premiere" htmlFor="is_home">Премьера на главном странице</label>
                                        </div>
                                        <div className="formColRowFlexDiv">
                                            <input type="checkbox" className="btn-check" id="is_page" autoComplete="off" {...register("is_page")} />
                                            <label className="btn btn-outline-success w-100 premiere" htmlFor="is_page">Премьера на странице фильмы</label>
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <div className="col-md-6">
                                {(watchIsHome) && <div className="d-flex mb-2 w-100">
                                    <div className="text-center me-3">
                                        <div className="uploaded-image border-radius-5">
                                            {previewBannerImage ?
                                                <img className="w-100 d-block" src={previewBannerImage} /> :
                                                (model && model.banner_image ? <a href={model.banner_image} className="w-100" target="_blank">
                                                    <img className="w-100 d-block" src={model.banner_image} />
                                                </a> : <p className="text-white w-100 my-0">Баннер на главном</p>)}
                                        </div>
                                        <a role="button" className="text-white mt-2 d-inline-block" onClick={() => {
                                            bannerImageRef.current.click();
                                        }}>{previewBannerImage ? "Изменить" : "Загрузить"}</a>
                                    </div>

                                    <div className="w-100">
                                        {watchIsHome && <>
                                            <div className="formColRowFlexDiv">
                                                <input {...register("subtitle_uz", { required: false })} className={"formColOneRowFlexInput " + (errors.subtitle_uz?.type)} type="text" placeholder="Введите текст для баннера на узбекском" />
                                            </div>
                                            <div className="formColRowFlexDiv">
                                                <input {...register("subtitle_ru", { required: false })} className={"formColOneRowFlexInput " + (errors.subtitle_ru?.type)} type="text" placeholder="Введите текст для баннера на русском" />
                                            </div>
                                        </>}
                                    </div>

                                </div>}
                                {(watchIsPage) && <div className="d-flex mb-2 w-100">
                                    <div className="text-center me-3">
                                        <div className="uploaded-image border-radius-5">
                                            {previewPageImage ?
                                                <img className="w-100 d-block" src={previewPageImage} />
                                                : (model && model.page_image ? <a href={model.page_image} className="w-100" target="_blank">
                                                    <img className="w-100 d-block" src={model.page_image} />
                                                </a> : <p className="text-white w-100 my-0">Баннер на странице фильмы</p>)}
                                        </div>
                                        <a role="button" className="text-white mt-2 d-inline-block" onClick={() => {
                                            pageImageRef.current.click();
                                        }}>{previewPageImage ? "Изменить" : "Загрузить"}</a>
                                    </div>
                                </div>}

                            </div>

                            <div className="col-lg-6">

                                <form className="admin-form" onSubmit={handleSubmit(onSubmit)}>
                                    <div>
                                        <p className="text-white">Данные сериала</p>
                                        <hr />
                                    </div>
                                    <div className="formColRowFlexDiv">
                                        <p className="formColOneRowFlexLabel">Дата релиза *:</p>
                                        <input {...register("release_date", { required: true })} style={{ width: "150px" }} className={"formColOneRowFlexInput " + (errors.release_date?.type)} type="date" />
                                        <input type="time" {...register("release_time", { required: true })} style={{ width: "100px" }} className={"formColOneRowFlexInput " + (errors.release_time?.type)} />
                                    </div>
                                    <div className="formColRowFlexDiv">
                                        <p className="formColOneRowFlexLabel">Название *:</p>
                                        <input {...register("name", { required: true })} className={"formColOneRowFlexInput " + (errors.name?.type)} type="text" placeholder="Введите название" />
                                    </div>
                                    <div className="formColRowFlexDiv">
                                        <p className="formColOneRowFlexLabel">Сценаристы:</p>
                                        <Controller
                                            control={control}
                                            rules={{ required: false }}
                                            name="scenarists"
                                            render={({ field }) => (
                                                <AsyncCreatableSelect
                                                    {...field}
                                                    isMulti
                                                    className={(errors.scenarists?.type)}
                                                    placeholder=" - Выберите сценаристов - "
                                                    profa={(PROFA_SCENARIY)}
                                                />
                                            )}
                                        />
                                    </div>
                                    <div className="formColRowFlexDiv">
                                        <p className="formColOneRowFlexLabel">Режиссёры:</p>
                                        <Controller
                                            control={control}
                                            rules={{ required: false }}
                                            name="directors"
                                            render={({ field }) => (
                                                <AsyncCreatableSelect
                                                    {...field}
                                                    isMulti
                                                    className={(errors.directors?.type)}
                                                    placeholder=" - Выберите режиссёров - "
                                                    profa={(PROFA_REJISYOR)}
                                                />
                                            )}
                                        />
                                    </div>
                                    <div className="formColRowFlexDiv">
                                        <p className="formColOneRowFlexLabel">Операторы:</p>
                                        <Controller
                                            control={control}
                                            rules={{ required: false }}
                                            name="operators"
                                            render={({ field }) => (
                                                <AsyncCreatableSelect
                                                    {...field}
                                                    isMulti
                                                    className={(errors.operators?.type)}
                                                    placeholder=" - Выберите операторов - "
                                                    profa={(PROFA_OPERATOR)}
                                                />
                                            )}
                                        />
                                    </div>
                                    <div className="formColRowFlexDiv">
                                        <p className="formColOneRowFlexLabel">Художники:</p>
                                        <Controller
                                            control={control}
                                            rules={{ required: false }}
                                            name="xudojniks"
                                            render={({ field }) => (
                                                <AsyncCreatableSelect
                                                    {...field}
                                                    isMulti
                                                    className={(errors.xudojniks?.type)}
                                                    placeholder=" - Выберите художников - "
                                                    profa={(PROFA_XUDOJNIK)}
                                                />
                                            )}
                                        />
                                    </div>
                                    <div className="formColRowFlexDiv">
                                        <p className="formColOneRowFlexLabel">Композиторы:</p>
                                        <Controller
                                            control={control}
                                            rules={{ required: false }}
                                            name="compositors"
                                            render={({ field }) => (
                                                <AsyncCreatableSelect
                                                    {...field}
                                                    isMulti
                                                    className={(errors.compositors?.type)}
                                                    placeholder=" - Выберите композиторов - "
                                                    profa={(PROFA_KOMPOZITOR)}
                                                />
                                            )}
                                        />
                                    </div>
                                    <div className="formColRowFlexDiv">
                                        <p className="formColOneRowFlexLabel">Продюсеры:</p>
                                        <Controller
                                            control={control}
                                            rules={{ required: false }}
                                            name="producers"
                                            render={({ field }) => (
                                                <AsyncCreatableSelect
                                                    {...field}
                                                    isMulti
                                                    className={(errors.producers?.type)}
                                                    placeholder=" - Выберите продюсеров - "
                                                    profa={(PROFA_PRODYUSER)}
                                                />
                                            )}
                                        />
                                    </div>

                                    <div className="formColRowFlexDiv">
                                        <p className="formColOneRowFlexLabel">Актёры:</p>
                                        <Controller
                                            control={control}
                                            rules={{ required: false }}
                                            name="actors"
                                            render={({ field }) => (
                                                <AsyncCreatableSelect
                                                    {...field}
                                                    isMulti
                                                    className={(errors.actors?.type)}
                                                    placeholder=" - Выберите актёры - "
                                                    profa={(PROFA_AKTYOR)}
                                                />
                                            )}
                                        />
                                    </div>
                                    <div className="formColRowFlexDiv">
                                        <p className="formColOneRowFlexLabel">Киностудия:</p>
                                        <Controller
                                            control={control}
                                            rules={{ required: false }}
                                            name="studios"
                                            render={({ field }) => (
                                                <AsyncCreatableSelect
                                                    {...field}
                                                    isMulti
                                                    className={(errors.studios?.type)}
                                                    placeholder=" - Выберите киностудии - "
                                                    profa={(PROFA_STUDIO)}
                                                />
                                            )}
                                        />
                                    </div>


                                    <div className="formColRowFlexDiv">
                                        <p className="formColOneRowFlexLabel">Жанры:</p>
                                        <Controller
                                            control={control}
                                            rules={{ required: false }}
                                            name="genres"
                                            render={({ field }) => (
                                                <AsyncSelect
                                                    {...field}
                                                    isMulti
                                                    className={(errors.genres?.type)}
                                                    defaultOptions
                                                    placeholder=" - Выберите жанров - "
                                                    loadOptions={loadGenres}
                                                />
                                            )}
                                        />
                                    </div>
                                    <div className="formColRowFlexDiv">
                                        <p className="formColOneRowFlexLabel">Страны:</p>
                                        <Controller
                                            control={control}
                                            rules={{ required: false }}
                                            name="countries"
                                            render={({ field }) => (
                                                <AsyncSelect
                                                    {...field}
                                                    isMulti
                                                    className={(errors.countries?.type)}
                                                    defaultOptions
                                                    placeholder=" - Выберите страны - "
                                                    loadOptions={loadCountries}
                                                />
                                            )}
                                        />
                                    </div>

                                    <div className="formColRowFlexDiv">
                                        <p className="formColOneRowFlexLabel">Монтаж:</p>
                                        <Controller
                                            control={control}
                                            rules={{ required: false }}
                                            name="montajs"
                                            render={({ field }) => (
                                                <AsyncCreatableSelect
                                                    {...field}
                                                    isMulti
                                                    className={(errors.montajs?.type)}
                                                    placeholder=" - Выберите монтажеров - "
                                                    profa={(PROFA_MONTAJ)}
                                                />
                                            )}
                                        />
                                    </div>

                                    <div className="formColRowFlexDiv">
                                        <p className="formColOneRowFlexLabel">Лэйбл:</p>
                                        <Controller
                                            control={control}
                                            rules={{ required: false }}
                                            name="leybls"
                                            render={({ field }) => (
                                                <AsyncCreatableSelect
                                                    {...field}
                                                    isMulti
                                                    className={(errors.leybls?.type)}
                                                    placeholder=" - Выберите лэйблов - "
                                                    profa={(PROFA_LEYBL)}
                                                />
                                            )}
                                        />
                                    </div>

                                    <div className="formColRowFlexDiv d-none">
                                        <p className="formColOneRowFlexLabel">Картинка:</p>
                                        <input {...register("file", { required: !model })} type="file" accept="image/*" ref={imageRef} className={"formColOneRowFlexInput " + (errors.file?.type)} onChange={(e) => {
                                            if (e.target.files.length > 0) {
                                                var file = e.target.files[0];
                                                setValue("file", file);
                                                trigger('file');
                                                var reader = new FileReader();
                                                var url = reader.readAsDataURL(file);

                                                reader.onloadend = function (e) {
                                                    setPreviewImage([reader.result])
                                                }
                                            } else {
                                                setValue("file", null);
                                                trigger('file');
                                                setPreviewImage(null);
                                            }
                                        }} />
                                    </div>
                                    <div className="formColRowFlexDiv d-none">
                                        <p className="formColOneRowFlexLabel">Картинка:</p>
                                        <input {...register("portrait_file", { required: !model })} type="file" accept="image/*" ref={portraitImageRef} className={"formColOneRowFlexInput " + (errors.portrait_file?.type)} onChange={(e) => {
                                            if (e.target.files.length > 0) {
                                                var file = e.target.files[0];
                                                setValue("portrait_file", file);
                                                trigger('portrait_file');
                                                var reader = new FileReader();
                                                var url = reader.readAsDataURL(file);

                                                reader.onloadend = function (e) {
                                                    setPreviewPortraitImage([reader.result])
                                                }
                                            } else {
                                                setValue("portrait_file", null);
                                                trigger('portrait_file');
                                                setPreviewPortraitImage(null);
                                            }
                                        }} />
                                    </div>

                                    <div className="formColRowFlexDiv d-none">
                                        <p className="formColOneRowFlexLabel">Баннер:</p>
                                        <input {...register("banner_file")} type="file" ref={bannerImageRef} className={"formColOneRowFlexInput " + (errors.banner_file?.type)} onChange={(e) => {
                                            if (e.target.files.length > 0) {
                                                var file = e.target.files[0];
                                                setValue("banner_file", file);
                                                trigger("banner_file");
                                                var reader = new FileReader();
                                                var url = reader.readAsDataURL(file);

                                                reader.onloadend = function (e) {
                                                    setPreviewBannerImage([reader.result])
                                                }
                                            } else {
                                                setValue("banner_file", null);
                                                trigger('banner_file');
                                                setPreviewBannerImage(null);
                                            }
                                        }} />
                                    </div>
                                    <div className="formColRowFlexDiv d-none">
                                        <p className="formColOneRowFlexLabel">Баннер на своем странице:</p>
                                        <input {...register("page_file")} type="file" ref={pageImageRef} accept="image/*" className={"formColOneRowFlexInput " + (errors.page_file?.type)} onChange={(e) => {
                                            if (e.target.files.length > 0) {
                                                var file = e.target.files[0];
                                                setValue("page_file", file);
                                                var reader = new FileReader();
                                                var url = reader.readAsDataURL(file);

                                                reader.onloadend = function (e) {
                                                    setPreviewPageImage([reader.result])
                                                }
                                            } else {
                                                setValue("page_file", null);
                                                trigger('page_file');
                                                setPreviewPageImage(null);
                                            }
                                        }} />
                                    </div>

                                    <div className="d-flex justify-content-end">
                                        <button type="submit" disabled={isLoading}>
                                            {isLoading ? (
                                                <i className="fa fa-spinner fa-2x rotating "></i>
                                            ) : ("Сохранить")}
                                        </button>
                                    </div>
                                </form>
                            </div>
                            <div className="col-lg-4">
                                <div action="" className="admin-form">
                                    {!model && <p className="text-white">Сохраните сериал чтобы добавить эпизоды</p>}
                                    {model && <>
                                        <p className="text-white">Добавить новые эпизоды</p>
                                        <Dropzone onDrop={acceptedFiles => setEpisodes(oldEpisodes => [...oldEpisodes, ...acceptedFiles])}>
                                            {({ getRootProps, getInputProps }) => (
                                                <section>
                                                    <div {...getRootProps()}>
                                                        <input {...getInputProps()} accept="video/*" />
                                                        <p className="text-white text-center border p-3 cursor-pointer">Перетащите файлы или нажмите сюда</p>
                                                    </div>
                                                </section>
                                            )}
                                        </Dropzone>
                                        <ul className={`list-group list-group-flush ${episodes.length > 0 ? 'list-group-numbered2' : ''}`}>
                                            {episodes.length + model.episodes.filter(episode => episode.status == 3).length == 0 && <li className="list-group-item list-group-item-dark">Пока пусто</li>}

                                            {episodes.map((episode, index) => {
                                                return <UploadEposide wait={index != 0} episode={episode} key={index} serial_id={model.id} fetchData={fetchData} deleteThis={deleteThis} />;
                                            })}

                                            {model.episodes.filter(episode => episode.status == 3).map((episode, index) => {
                                                return <ExistingEpisode key={index} episode={episode} fetchData={fetchData} />;
                                            })}
                                        </ul>
                                    </>}
                                </div>

                                <div className="admin-form mt-4">
                                    {model && <>
                                        <p className="text-white">Существующие эпизоды</p>

                                        <ul className={`list-group list-group-flush ${episodes.length > 0 ? 'list-group-numbered2' : ''}`}>
                                            {model.episodes.filter(episode => episode.status != 3).length == 0 && <li className="list-group-item list-group-item-dark">Пока пусто</li>}

                                            {model.episodes.filter(episode => episode.status != 3).map((episode, index) => {
                                                return <ExistingEpisode key={index} episode={episode} fetchData={fetchData} />;
                                            })}
                                        </ul>
                                    </>}
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}