import React from "react";
import Modal from 'react-modal';
import { toast } from "react-toastify";
import { get } from "../utils/request";
import EpisodeForm from "./forms/EpisodeForm";

const customStyles = {
    content: {
        top: '135px',
        left: '30px',
        right: '30px',
        bottom: '30px',
        backgroundColor: "#232636"
    },
};

const controllerName = "episode";

export default function ExistingEpisode(props) {
    const [isOpen, setIsOpen] = React.useState(false);

    function openModal() {
        setIsOpen(true);
    }

    function closeModal() {
        setIsOpen(false);

        if (props.fetchData)
            props.fetchData();
    }

    const handleDelete = async (id) => {
        if (window.confirm("Удалить?")) {
            let res = await get(`${controllerName}/delete?id=` + id);

            if (res?.status === "success") {
                toast.success("Запись успешно удалена.");

                if (props.fetchData) {
                    props.fetchData();
                }
            }
        }
    }

    return (
        <li className="list-group-item list-group-item-dark py-3">
            <Modal
                isOpen={isOpen}
                onRequestClose={closeModal}
                style={customStyles}
            >
                <EpisodeForm id={props.episode.id} closeModal={closeModal} />
            </Modal>
            <div className="d-flex">
                <a role="button" className="w-100 text-white d-block">
                    Сезон: {props.episode.season}, Сериа: {props.episode.seria} - {props.episode.name} 
                    {props.episode.status == 12 && <span className="ms-3 badge text-warning">[Модерация]</span>}
                    {props.episode.status == 15 && <span className="ms-3 badge text-danger">[Отклонено]</span>}
                </a>
                {<>
                    <a role="button" className="text-white me-3" onClick={() => openModal()}>
                        <i className="fa fa-pencil"></i>
                    </a>
                    <a role="button" className="text-white" onClick={() => {
                        handleDelete(props.episode.id);
                    }}>
                        <i className="fa fa-trash"></i>
                    </a>
                </>}
            </div>
        </li>
    );
}
