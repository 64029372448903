import React from "react";
import axios from "axios";
import { useState } from "react";
import { arrayToFormData, useIsMounted } from "../utils/functions";
import Modal from 'react-modal';
import EpisodeForm from "./forms/EpisodeForm";
import { post } from "../utils/request";
import moment from "moment";
import { FILE_HOST_URL, SERVICE_HOST_URL } from "../config";

const customStyles = {
    content: {
        top: '135px',
        left: '30px',
        right: '30px',
        bottom: '30px',
        backgroundColor: "#232636"
    },
};

export default function UploadEposide(props) {
    const [isLoading, setIsLoading] = useState(false);
    const [progress, setProgress] = useState(0);

    const [filename, setfilename] = useState("");
    const [filenames, setfilenames] = useState("");

    const [duration, setduration] = useState("");

    const [isOpen, setIsOpen] = React.useState(false);

    const [id, setId] = useState(null);

    const isMounted = useIsMounted();

    React.useEffect(() => {
        let fetch = async () => {
            window.onbeforeunload = function (e) {
                e = e || window.event;

                // For IE and Firefox prior to version 4
                if (e) {
                    e.returnValue = 'Закрыть?';
                }

                // For Safari
                return 'Закрыть?';
            };

            setIsLoading(true);

            let res = await axios.post(`${SERVICE_HOST_URL}:3010/upload?dir=serial`, arrayToFormData({
                file: props.episode
            }), {
                onUploadProgress: (event) => {
                    setProgress(event.loaded / event.total * 100)
                }
            });

            if (res && res.data && res.data.status === "success") {
                // setfilename(res.data.filename);
                // setfilenames(res.data.filenames);
                // setduration(res.data.duration);
                let name = props.episode.name.replace(".mp4", "").replace(".avi", "").replace(".mpeg", "").replace(".mov", "");

                let response = await post(`episode/create`, arrayToFormData({
                    name: name,
                    season: "-1",
                    seria: "-1",
                    filename: res.data.filename,
                    filenames: res.data.filenames,
                    release_date: moment().format("YYYY-MM-DD HH:mm:ss"),
                    status: 3,
                    duration: res.data.duration,
                    serial_id: props.serial_id
                }));

                if (response?.status === "success") {
                    if (isMounted) {
                        setIsLoading(false);
                        setId(response.model.id);
                        closeModalWithFetch();
                    }
                }
            }

            setIsLoading(false);
        }
        if (props.episode && !props.wait) {
            fetch();
        }

    }, [props.episode]);

    function openModal() {
        setIsOpen(true);
    }

    function closeModalWithFetch() {
        setIsOpen(false);

        if (props.fetchData) {
            props.fetchData();
        }

        if (props.deleteThis) {
            props.deleteThis(props.episode.name);
        }
    }

    function closeModal() {
        setIsOpen(false);

        if (props.fetchData) {
            props.fetchData();
        }
    }

    function closeModalWithDelete() {
        setIsOpen(false);

        if (props.fetchData) {
            props.fetchData();
        }

        if (props.deleteThis) {
            props.deleteThis(props.episode.name);
        }
    }

    return (
        <li className="list-group-item list-group-item-dark py-3">
            <Modal
                isOpen={isOpen}
                onRequestClose={closeModal}
                style={customStyles}
                contentLabel="Example Modal"
            >
                {id && <EpisodeForm id={id} closeModal={closeModalWithDelete} />}
            </Modal>
            {!props.wait ? <><div className="d-flex">
                <a role="button" className="w-100 text-white d-block">
                    {props.episode.name}
                </a>
                {!isLoading && <>
                    <a role="button" className="text-white me-3" onClick={() => openModal()}>
                        <i className="fa fa-pencil"></i>
                    </a>
                    <a role="button" className="text-white" onClick={() => props.deleteThis && props.deleteThis()}>
                        <i className="fa fa-trash"></i>
                    </a>
                </>}
            </div>
                {isLoading && progress != 100 && <div>
                    <p className="text-white">Загрузка файла... </p>
                    <div className="row">
                        <div className="col-12">
                            <div className="progress mb-4">
                                <div className="progress-bar" role="progressbar" style={{ width: `${progress}%` }}></div>
                            </div>
                        </div>
                    </div>

                </div>}
                {isLoading && progress == 100 && <div>
                    <p className="text-white">Конвертация файла... </p>
                    <div className="row">
                        <div className="col-12">
                            <div className="progress mb-4">
                                <div className={`progress-bar progress-bar-striped progress-bar-animated`} role="progressbar" style={{ width: `${100}%` }}></div>
                            </div>
                        </div>
                    </div>
                </div>}
            </> : <div>
                <p className="text-white">{props.episode.name} - Ожидания очереди... </p>
                <div className="row">
                    <div className="col-12">
                        <div className="progress mb-4">
                            <div className={`progress-bar progress-bar-striped progress-bar-animated`} role="progressbar" style={{ width: `${100}%` }}></div>
                        </div>
                    </div>
                </div>
            </div>}
        </li>
    );
}