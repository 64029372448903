import React from 'react';
import axios from 'axios';

import {
    BrowserRouter as Router,
    Switch,
    Route
} from "react-router-dom";

import { ToastContainer } from 'react-toastify';

import { AuthContext } from './components/context';
import { getData, removeData, storeData } from './utils/store';
import { authReducer, initialAuthState } from './reducers/authReducer';

import './App.css';
import 'react-toastify/dist/ReactToastify.css';

import Home from './pages/Home';
import Login from './pages/Login';
import Navbar from './components/Navbar';
import NotFound from './pages/NotFound';
import Music from './pages/Music/Music';
import MusicHandle from './pages/Music/MusicHandle';
import Persons from './pages/Settings/Persons/Persons';
import Genres from './pages/Settings/Genres/Genres';
import Countries from './pages/Settings/Countries/Countries';
import GenreHandle from './pages/Settings/Genres/GenreHandle';
import PersonHandle from './pages/Settings/Persons/PersonHandle';
import CountryHandle from './pages/Settings/Countries/CountryHandle';
import Admins from './pages/Settings/Admins/Admins';
import AdminHandle from './pages/Settings/Admins/AdminHandle';
import PasswordHandle from './pages/Settings/Password/PasswordHandle';
import Categories from './pages/Settings/Category/Categories';
import CategoryHandle from './pages/Settings/Category/CategoryHandle';
import Album from './pages/Album/Album';
import AlbumHandle from './pages/Album/AlbumHandle';
import { initialPersonState, personReducer } from './reducers/personReducer';
import { get } from './utils/request';
import { categoryReducer, initialCategoryState } from './reducers/categoryReducer';
import { genreReducer, initialGenreState } from './reducers/genreReducer';
import { albumReducer, initialAlbumState } from './reducers/albumReducer';
import { serialReducer, initialSerialState } from './reducers/serialReducer';
import { countryReducer, initialCountryState } from './reducers/countryReducer';
import { initialProfaState, profaReducer } from './reducers/profaReducer';
import Clip from './pages/Clip/Clip';
import ClipHandle from './pages/Clip/ClipHandle';
import Concert from './pages/Concert/Concert';
import ConcertHandle from './pages/Concert/ConcertHandle';
import Humor from './pages/Humor/Humor';
import HumorHandle from './pages/Humor/HumorHandle';
import Film from './pages/Film/Film';
import FilmHandle from './pages/Film/FilmHandle';
import Serial from './pages/Serial/Serial';
import SerialHandle from './pages/Serial/SerialHandle';
import ConvertHandle from './pages/Settings/Convert/ConvertHandle';
import MultiEditMusic from './components/MultiEditMusic';
import Playlists from './pages/Settings/Playlist/Playlists';
import PlaylistHandle from './pages/Settings/Playlist/PlaylistHandle';
import { initialPlaylistState, playlistReducer } from './reducers/playlistReducer';
import SubscriptionHandle from './pages/Settings/Subscrption/SubscrptionHandle';
import Subscriptions from './pages/Settings/Subscrption/Subscriptions';
import Telenovella from './pages/Telenovella/Telenovella';
import TelenovellaHandle from './pages/Telenovella/TelenovellaHandle';
import Report from './pages/Report/Report';
import Users from './pages/Settings/Users/Users';
import UserTransactions from './pages/Settings/Users/UserTransactions';
import Template from './pages/Template/Template';
import EpisodeHandle from './pages/Serial/EpisodeHandle';
import Transaction from './pages/Transaction/Transaction';
import TransactionHandle from './pages/Transaction/TransactionHandle';
import ReportHandle from './pages/Report/ReportHandle';
import Organization from './pages/Settings/Organization/Organization';
import OrganizationHandle from './pages/Settings/Organization/OrganizationHandle';
import OrganizationAccounts from './pages/Settings/Organization/OrganizationAccounts';
import OrganizationAccountHandle from './pages/Settings/Organization/OrganizationAccountHandle';
import Partners from './pages/Settings/Partners/Partners';
import PartnerHandle from './pages/Settings/Partners/PartnerHandle';
import RenderHandle from './pages/Settings/Render/RenderHandle';
import ActiveSubscriptions from './pages/Settings/ActiveSubscription/ActiveSubscriptions';
import CreateActiveSubscription from './pages/Settings/ActiveSubscription/CreateActiveSubscription';
import Promocodes from './pages/Settings/Promocode/Promocodes';
import PromocodeHandle from './pages/Settings/Promocode/PromocodeHandle';
import Adverts from './pages/Settings/Advert/Adverts';
import AdvertHandle from './pages/Settings/Advert/AdvertHandle';
import UzRapRenderHandle from './pages/Settings/UzRapRender/UzRapRenderHandle';

function App() {
    const [isLoading, setIsLoading] = React.useState(true);

    const [authState, loginDispatch] = React.useReducer(
        authReducer,
        initialAuthState,
    );

    const [personState, personDispatch] = React.useReducer(
        personReducer,
        initialPersonState
    )

    const [categoryState, categoryDispatch] = React.useReducer(
        categoryReducer,
        initialCategoryState
    )

    const [playlistState, playlistDispatch] = React.useReducer(
        playlistReducer,
        initialPlaylistState
    )

    const [genreState, genreDispatch] = React.useReducer(
        genreReducer,
        initialGenreState
    )

    const [albumState, albumDispatch] = React.useReducer(
        albumReducer,
        initialAlbumState
    )

    const [serialState, serialDispatch] = React.useReducer(
        serialReducer,
        initialSerialState
    )

    const [countryState, countryDispatch] = React.useReducer(
        countryReducer,
        initialCountryState
    )

    const [profaState, profaDispatch] = React.useReducer(
        profaReducer,
        initialProfaState
    )

    const authContext = React.useMemo(
        () => ({
            login: async user => {
                if (user) {
                    await storeData('user', user);
                    axios.defaults.headers.common["Authorization"] = "Bearer " + user.token;
                }

                loginDispatch({ type: 'LOGIN', payload: user });
            },
            logout: async () => {
                await removeData('user');
                loginDispatch({ type: 'LOGOUT' });
            },
            loadPersons: async () => {
                try {
                    let res = await get('person/index?limit=1000000');
                    personDispatch({ type: "FILL", payload: res.models.map((model) => ({ value: model.id, label: model.name, model: { ...model } })) });
                } catch (ex) {
                    personDispatch({ type: "CLEAR" });
                }
            },
            loadCategories: async () => {
                try {
                    let res = await get('category/index?limit=1000000');
                    categoryDispatch({ type: "FILL", payload: res.models.map((model) => ({ value: model.id, label: model.name_ru, model: { ...model } })) });
                } catch (ex) {
                    categoryDispatch({ type: "CLEAR" });
                }
            },
            loadPlaylists: async () => {
                try {
                    let res = await get('playlist/index?limit=1000000');
                    playlistDispatch({ type: "FILL", payload: res.models.map((model) => ({ value: model.id, label: model.name_ru, model: { ...model } })) });
                } catch (ex) {
                    playlistDispatch({ type: "CLEAR" });
                }
            },
            loadGenres: async () => {
                try {
                    let res = await get('genre/index?limit=1000000');
                    genreDispatch({ type: "FILL", payload: res.models.map((model) => ({ value: model.id, label: model.name_ru, model: { ...model } })) });
                } catch (ex) {
                    genreDispatch({ type: "CLEAR" });
                }
            },
            loadAlbums: async () => {
                try {
                    let res = await get('album/simple-list?limit=1000000');
                    albumDispatch({ type: "FILL", payload: res.models.map((model) => ({ value: model.id, label: model.name, model: { ...model } })) });
                } catch (ex) {
                    albumDispatch({ type: "CLEAR" });
                }
            },
            loadSerials: async () => {
                try {
                    let res = await get('serial/simple-list');
                    serialDispatch({ type: "FILL", payload: res.map((model) => ({ value: model.id, label: model.name, model: { ...model } })) });
                } catch (ex) {
                    serialDispatch({ type: "CLEAR" });
                }
            },
            loadCountries: async () => {
                try {
                    let res = await get('country/index?limit=1000000');
                    countryDispatch({ type: "FILL", payload: res.models.map((model) => ({ value: model.id, label: model.name_ru, model: { ...model } })) });
                } catch (ex) {
                    countryDispatch({ type: "CLEAR" });
                }
            },
            loadProfas: async () => {
                try {
                    let res = await get('person/profas?limit=1000000');
                    profaDispatch({
                        type: "FILL",
                        payload: Object.keys(res).map((key, index) => {
                            return {
                                value: key,
                                label: res[key]
                            }
                        })
                    });
                } catch (ex) {
                    profaDispatch({ type: "CLEAR" });
                }
            },
            authState,
            personState,
            categoryState,
            playlistState,
            genreState,
            albumState,
            countryState,
            profaState,
            serialState
        }),
        [authState, personState, categoryState, genreState, albumState, countryState, profaState, playlistState, serialState],
    );

    React.useEffect(async () => {
        let user = await getData('user');

        axios.interceptors.response.use(
            (response) => {
                return response;
            },
            (error) => {
                if (error.response)
                    if (error.response.status === 401 || error.response.status === 403) {
                        removeData('user');
                        loginDispatch({ type: 'LOGOUT' });
                    } else {
                        return Promise.reject(error);
                    }
            }
        );

        if (user)
            axios.defaults.headers.common["Authorization"] = "Bearer " + user.token;

        authContext.login(user);

        await Promise.all([
            authContext.loadPersons(),
            authContext.loadCategories(),
            authContext.loadPlaylists(),
            authContext.loadGenres(),
            authContext.loadAlbums(),
            authContext.loadSerials(),
            authContext.loadCountries(),
            authContext.loadProfas(),
        ]);

        setIsLoading(false);
    }, []);

    if (isLoading) {
        return <div style={{ width: "100vw", height: '100vh', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <i className="fa fa-spinner rotating fa-5x text-white"></i>
        </div>
    }

    return (
        <AuthContext.Provider value={authContext}>
            <Router>
                {!authState.isAuth ? <Login /> : <div className="rizanova-content theme-dark">
                    <Navbar />
                    <Switch>
                        <Route exact path="/" component={Home} />
                        <Route exact path="/settings" component={Persons} />
                        <Route exact path="/settings/password" component={PasswordHandle} />
                        <Route exact path="/settings/convert" component={ConvertHandle} />
                        <Route exact path="/settings/render" component={RenderHandle} />
                        <Route exact path="/settings/uzrap-render" component={UzRapRenderHandle} />
                        <Route exact path="/settings/person/create" component={PersonHandle} />
                        <Route exact path="/settings/person/:id" component={PersonHandle} />
                        <Route exact path="/settings/genre" component={Genres} />
                        <Route exact path="/settings/genre/create" component={GenreHandle} />
                        <Route exact path="/settings/genre/:id" component={GenreHandle} />
                        <Route exact path="/settings/advert" component={Adverts} />
                        <Route exact path="/settings/advert/create" component={AdvertHandle} />
                        <Route exact path="/settings/advert/:id" component={AdvertHandle} />
                        <Route exact path="/settings/country" component={Countries} />
                        <Route exact path="/settings/country/create" component={CountryHandle} />
                        <Route exact path="/settings/country/:id" component={CountryHandle} />
                        <Route exact path="/settings/subscription" component={Subscriptions} />
                        <Route exact path="/settings/subscription/create" component={SubscriptionHandle} />
                        <Route exact path="/settings/subscription/:id" component={SubscriptionHandle} />
                        <Route exact path="/settings/category" component={Categories} />
                        <Route exact path="/settings/category/create" component={CategoryHandle} />
                        <Route exact path="/settings/category/:id" component={CategoryHandle} />
                        <Route exact path="/settings/organization" component={Organization} />
                        <Route exact path="/settings/organization/create" component={OrganizationHandle} />
                        <Route exact path="/settings/organization/:id" component={OrganizationHandle} />
                        <Route exact path="/settings/organization/:organization_id/users" component={OrganizationAccounts} />
                        <Route exact path="/settings/organization/:organization_id/users/create" component={OrganizationAccountHandle} />
                        <Route exact path="/settings/organization/:organization_id/users/:id" component={OrganizationAccountHandle} />
                        <Route exact path="/settings/playlist" component={Playlists} />
                        <Route exact path="/settings/playlist/create" component={PlaylistHandle} />
                        <Route exact path="/settings/playlist/:id" component={PlaylistHandle} />
                        <Route exact path="/settings/admin" component={Admins} />
                        <Route exact path="/settings/admin/create" component={AdminHandle} />
                        <Route exact path="/settings/admin/:id" component={AdminHandle} />
                        <Route exact path="/settings/partner" component={Partners} />
                        <Route exact path="/settings/partner/create" component={PartnerHandle} />
                        <Route exact path="/settings/partner/:id" component={PartnerHandle} />
                        <Route exact path="/settings/promocode" component={Promocodes} />
                        <Route exact path="/settings/promocode/create" component={PromocodeHandle} />
                        <Route exact path="/settings/promocode/:id" component={PromocodeHandle} />
                        <Route exact path="/music" component={Music} />
                        <Route exact path="/music/album/:album_id" component={Music} />
                        <Route exact path="/music/create" component={MusicHandle} />
                        <Route exact path="/music/create/:album_id/:singer_id" component={MusicHandle} />
                        <Route exact path="/music/:id" component={MusicHandle} />
                        <Route exact path="/music/update/:ids" component={MultiEditMusic} />
                        <Route exact path="/music/album/:album_id/:ids" component={MultiEditMusic} />
                        <Route exact path="/album" component={Album} />
                        <Route exact path="/album/create" component={AlbumHandle} />
                        <Route exact path="/album/:id" component={AlbumHandle} />
                        <Route exact path="/clip" component={Clip} />
                        <Route exact path="/clip/create" component={ClipHandle} />
                        <Route exact path="/clip/:id" component={ClipHandle} />
                        <Route exact path="/concert" component={Concert} />
                        <Route exact path="/concert/create" component={ConcertHandle} />
                        <Route exact path="/concert/:id" component={ConcertHandle} />
                        <Route exact path="/humor" component={Humor} />
                        <Route exact path="/humor/create" component={HumorHandle} />
                        <Route exact path="/humor/:id" component={HumorHandle} />
                        <Route exact path="/film" component={Film} />
                        <Route exact path="/film/create" component={FilmHandle} />
                        <Route exact path="/film/:id" component={FilmHandle} />
                        <Route exact path="/telenovella" component={Telenovella} />
                        <Route exact path="/telenovella/create" component={TelenovellaHandle} />
                        <Route exact path="/telenovella/:id" component={TelenovellaHandle} />
                        <Route exact path="/serial" component={Serial} />
                        <Route exact path="/serial/create" component={SerialHandle} />
                        <Route exact path="/serial/:id" component={SerialHandle} />
                        <Route exact path="/episode/:id" component={EpisodeHandle} />
                        <Route exact path="/report" component={Report} />
                        <Route exact path="/report/generate" component={ReportHandle} />
                        <Route exact path="/active-subscriptions" component={ActiveSubscriptions} />
                        <Route exact path="/active-subscriptions/create" component={CreateActiveSubscription} />
                        <Route exact path="/users" component={Users} />
                        <Route exact path="/users/transactions/:id" component={UserTransactions} />
                        <Route exact path="/template" component={Template} />
                        <Route exact path="/transaction" component={Transaction} />
                        <Route exact path="/transaction/create" component={TransactionHandle} />
                        <Route exact path="/transaction/:id" component={TransactionHandle} />
                        <NotFound />
                    </Switch>
                    <ToastContainer />
                </div>
                }
            </Router>
        </AuthContext.Provider >
    );
}

export default App;