import React, { useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { AuthContext } from '../../components/context';
import { useForm, Controller } from "react-hook-form";
import Select from 'react-select';
import { arrayToFormData, useIsMounted } from '../../utils/functions';
import { toast } from 'react-toastify';
import { get, post } from '../../utils/request';
import axios from 'axios';
import { Collapse } from 'react-bootstrap';
import ReactHlsPlayer from 'react-hls-player';
import AsyncSelect from 'react-select/async';
import moment from 'moment';
import { FILE_HOST_URL, SERVICE_HOST_URL } from '../../config';

let statuses = [
    { value: "1", label: 'Доступен всем' },
    { value: "0", label: 'Ограничить доступ' },
    { value: "2", label: 'Доступ по ссылке' },
    { value: "12", label: 'Модерация' },
    { value: "15", label: 'Отклонен' },
];

const controllerName = "episode";
const title = "Эпизод";

export default function EpisodeForm(props) {
    const authContext = React.useContext(AuthContext);

    const [isLoading, setIsLoading] = useState(false);
    const [model, setModel] = useState(null);
    const [collapse, setCollapse] = useState(false);
    const [screenshots, setScreenshots] = useState([]);
    const [selectedScreenshot, setSelectedScreenshot] = useState(null);

    const { id } = props;

    const isMounted = useIsMounted();

    const imageRef = useRef();

    let [previewImage, setPreviewImage] = useState(null);
    let [previewVideo, setPreviewVideo] = useState(null);

    const defaultValues = {
        name: "",
        season: "",
        seria: "",
        filename: "",
        filenames: "",
        release_date: moment().format("YYYY-MM-DD"),
        release_time: "11:00",
        status: statuses[0],

        duration: "",
    }

    const { register, handleSubmit, formState: { errors }, control, reset, trigger, setValue } = useForm({
        defaultValues
    });

    React.useEffect(() => {

        if (props.filename)
            setValue("filename", props.filename);

        if (props.filenames)
            setValue("filenames", props.filenames);

        if (props.duration)
            setValue("duration", props.duration);

        if (props.name)
            setValue('name', props.name);

        if (props.filenames)
            setPreviewVideo(props.filenames);

        if (props.serial_id)
            setValue('serial_id', props.serial_id);

    }, [props]);


    const fetchData = async () => {
        setIsLoading(true);

        let res = await get(`${controllerName}/get?id=${id}`);

        if (isMounted) {
            setModel(res);

            setIsLoading(false);
        }
    }


    React.useEffect(() => {
        document.title = title;

        if (id)
            fetchData();

    }, []);

    React.useEffect(() => {
        if (model) {
            let dates = model.release_date?.split(" ");

            let release_date = dates?.length > 0 ? dates[0] : moment().format("YYYY-MM-DD");
            let release_time = dates?.length > 1 ? dates[1] : "11:00";

            if (model.screenshots == 1) {
                setScreenshots([
                    model.filenames?.replace("playlist.m3u8", "frame-1.jpg"),
                    model.filenames?.replace("playlist.m3u8", "frame-2.jpg"),
                    model.filenames?.replace("playlist.m3u8", "frame-3.jpg"),
                    model.filenames?.replace("playlist.m3u8", "frame-4.jpg"),
                ]);
            }


            reset({
                name: model.name,
                image: model.image,
                image_convert: model.image_convert,
                season: model.season,
                seria: model.seria,
                filename: model.filename,
                filenames: model.filenames,
                serial_id: authContext.serialState.find((serial) => serial.value === model.serial_id),
                duration: model.duration,
                status: model.status == 3 || model.status == 5 ? statuses[0] : statuses.find((status) => status.value == model.status),
                release_date: release_date,
                release_time: release_time
            });
        }
    }, [model]);

    const onSubmit = async (data) => {
        if (isLoading) return;

        setIsLoading(true);

        let res = await post(`${controllerName}/create${model ? `?id=${model.id}` : ''}`, arrayToFormData({
            ...data,
            file: data.file ? data.file : '',
            serial_id: data.serial_id ? (typeof data.serial_id == 'object' ? data.serial_id.value : data.serial_id) : '',
            status: Number.parseInt(statuses.find((status) => status.value == data.status.value).value),
            release_date: data.release_time ? data.release_date + " " + data.release_time : data.release_date,
            image_convert: selectedScreenshot ? selectedScreenshot : data.image_convert,
            image: selectedScreenshot ? selectedScreenshot : data.image,
        }));

        if (res?.status === "success") {
            if (isMounted) {

                window.onbeforeunload = function (e) {
                    return null;
                };

                setModel(res.model);

                if (props.closeModalWithDelete)
                    props.closeModalWithDelete();
                else if (props.closeModal)
                    props.closeModal();
                else
                    fetchData();

                toast.success(res.message);
            }
        }

        if (isMounted) {
            setIsLoading(false);
        }
    }

    const [serverFile, setServerFile] = useState(null);
    const [progress, setProgress] = useState(0);
    const [isServerFileLoading, setIsServerFileLoading] = useState(false);
    const [isDone, setIsDone] = useState(false);

    let serverFileInput = useRef();

    React.useEffect(() => {
        if (serverFile) {
            uploadToServer();

            window.onbeforeunload = function (e) {
                e = e || window.event;

                if (e) {
                    e.returnValue = 'Закрыть?';
                }

                return 'Закрыть?';
            };
        }
    }, [serverFile]);

    const uploadToServer = async () => {
        if (serverFile) {

            setValue("name", serverFile.name);
            setIsServerFileLoading(true);
            setIsDone(false);

            let res = await axios.post(`${SERVICE_HOST_URL}:3010/upload?dir=serial`, arrayToFormData({
                file: serverFile
            }), {
                onUploadProgress: (event) => {
                    setProgress(event.loaded / event.total * 100)
                }
            });

            if (res && res.data && res.data.status === "success") {
                setValue("filename", res.data.filename);
                setValue("filenames", res.data.filenames);
                setScreenshots(res.data.screenshots);
                setValue("duration", res.data.duration);

                setPreviewVideo(res.data.filenames);

                if (serverFileInput)
                    serverFileInput.current.value = null;
            }

            setIsDone(true);
        }
    }

    const loadSerials = (inputValue, callback) => {
        if (callback) {
            let models = authContext.serialState.sort((a, b) => a.label.localeCompare(b.label)).filter((model) => model.label.toLowerCase().indexOf(inputValue.toLowerCase()) > -1);
            callback(models);
            return models;
        }
    }

    return (
        <div className="row">
            <div className="col-lg-7">
                <div className="d-flex mb-2 w-100">
                    <div className="text-center me-3">
                        <div className="uploaded-image border-radius-5">
                            {previewImage ? <img className="w-100 d-block" src={previewImage} /> : (model && model.image ? <a href={model.image} className="w-100" target="_blank"><img className="w-100 d-block" src={model.image} /> </a> : <p className="text-white w-100 my-0">Картинка</p>)}
                        </div>
                        <a role="button" className="text-white mt-2 d-inline-block" onClick={() => {
                            imageRef.current.click();
                        }}>{previewImage ? "Изменить" : "Загрузить"}</a>
                    </div>
                    <div className="text-center me-3 mb-4">
                        <div className={"uploaded-audio border-radius-5 " + (previewVideo || model && model.filenames ? '' : ' bg ' + (errors.filename?.type))}>
                            {previewVideo ? <video className="w-100 d-block" controls>
                                <source src={previewVideo} type="video/mp4" />
                            </video> : (model && model.filenames ? <ReactHlsPlayer
                                src={model.filenames}
                                autoPlay={false}
                                controls={true}
                                width="100%"
                                height="auto"
                            /> : <p className="text-white w-100 my-0">Video</p>)}
                        </div>
                        <a role="button" className="text-white mt-2 d-inline-block" onClick={() => {
                            serverFileInput.current.click();
                        }}>{previewVideo ? "Изменить" : "Загрузить"}</a>
                    </div>

                    <div className="w-100">
                        <div className="formColRowFlexDiv">
                            <Controller
                                control={control}
                                rules={{ required: true }}
                                name="status"
                                render={({ field }) => (
                                    <Select
                                        {...field}
                                        placeholder="Параметы доступа"
                                        className="basic-single w-100"
                                        classNamePrefix="select"
                                        defaultValue={statuses[0]}
                                        isClearable={false}
                                        isSearchable={false}
                                        options={statuses}
                                    />
                                )}
                            />
                        </div>
                    </div>
                </div>

                <form className="admin-form" onSubmit={handleSubmit(onSubmit)}>
                    <div className="formColRowFlexDiv">
                        <p className="formColOneRowFlexLabel">Дата релиза *:</p>
                        <input {...register("release_date", { required: true })} style={{ width: "150px" }} className={"formColOneRowFlexInput " + (errors.release_date?.type)} type="date" />
                        <input type="time" {...register("release_time", { required: true })} style={{ width: "100px" }} className={"formColOneRowFlexInput " + (errors.release_time?.type)} />
                    </div>
                    <div className="formColRowFlexDiv">
                        <p className="formColOneRowFlexLabel">Название *:</p>
                        <input {...register("name", { required: true })} className={"formColOneRowFlexInput " + (errors.name?.type)} type="text" placeholder="Введите название" />
                    </div>
                    <div className="formColRowFlexDiv">
                        <p className="formColOneRowFlexLabel">Сезон *:</p>
                        <input {...register("season", { required: true })} className={"formColOneRowFlexInput " + (errors.season?.type)} type="number" min="1" placeholder="Введите номер сезона" />
                    </div>

                    <div className="formColRowFlexDiv">
                        <p className="formColOneRowFlexLabel">Сериа *:</p>
                        <input {...register("seria", { required: true })} className={"formColOneRowFlexInput " + (errors.seria?.type)} type="number" min="1" placeholder="Введите номер серии" />
                    </div>

                    <div className="formColRowFlexDiv">
                        <p className="formColOneRowFlexLabel">Сериал *:</p>
                        <Controller
                            control={control}
                            rules={{ required: true }}
                            name="serial_id"
                            defaultOptions
                            render={({ field }) => (
                                <AsyncSelect
                                    {...field}
                                    className={(errors.serial_id?.type)}
                                    defaultOptions
                                    placeholder=" - Выберите сериал - "
                                    loadOptions={loadSerials}
                                />
                            )}
                        />
                    </div>

                    <div className="my-3">
                        <a role="button" className="text-white-50" onClick={() => setCollapse(!collapse)}>{collapse ? "- Скрыть доп. инфо" : "+ Показать доп. инфо"}</a>
                    </div>

                    <Collapse in={collapse}>
                        <div className="mb-3">
                            <div className="formColRowFlexDiv">
                                <p className="formColOneRowFlexLabel fs-14">Ссылка на файл:</p>
                                <input value={model?.filenames.replace("/playlist.m3u8", "")} className={"formColOneRowFlexInput fs-14 cursor-pointer " /*+ (errors.filename?.type)*/} type="text" readOnly placeholder="Название файла на сервере" onClick={(e) => {
                                    if (e.target.value) {
                                        window.open(e.target.value, '_blank');
                                    }
                                }} />
                            </div>
                            <div className="formColRowFlexDiv">
                                <p className="formColOneRowFlexLabel fs-14">Продолжительность:</p>
                                <input {...register("duration", { required: true })} className={"formColOneRowFlexInput fs-14 "/* + (errors.duration?.type)*/} type="text" readOnly placeholder="Продолжительность файла" />
                            </div>
                            <div className="formColRowFlexDiv d-none">
                                <p className="formColOneRowFlexLabel">Сериал:</p>
                                <input {...register("serial_id", { required: true })} className={"formColOneRowFlexInput " + (errors.serial_id?.type)} type="text" placeholder="ID сериала" />
                            </div>
                            <div className="formColRowFlexDiv d-none">
                                <p className="formColOneRowFlexLabel">Картинка:</p>
                                <input {...register("file", { required: !model && !selectedScreenshot })} ref={imageRef} type="file" accept="image/*" className={"formColOneRowFlexInput " + (errors.file?.type)} onChange={(e) => {
                                    if (e.target.files.length > 0) {
                                        var file = e.target.files[0];
                                        setValue("file", file);
                                        var reader = new FileReader();
                                        var url = reader.readAsDataURL(file);

                                        reader.onloadend = function (e) {
                                            setPreviewImage([reader.result]);
                                            setSelectedScreenshot(null);
                                        }
                                    } else {
                                        setSelectedScreenshot(null);
                                    }
                                }} />
                            </div>
                        </div>
                    </Collapse>

                    <div className="d-flex justify-content-end">
                        <button type="submit" disabled={isLoading}>
                            {isLoading ? (
                                <i className="fa fa-spinner fa-2x rotating "></i>
                            ) : ("Сохранить")}
                        </button>
                    </div>
                </form>
            </div >
            <div className="col-md-5">
                <div className="text-end">
                    {props.closeModal && <a role="button" onClick={props.closeModal}><i className="fa fa-close fa-2x text-white"></i></a>}
                </div>
                <form className=" mb-4">
                    <div className="formColRowFlexDiv d-none">
                        <p className="formColOneRowFlexLabel">Загрузка файла на сервер:</p>
                        <input ref={serverFileInput} onChange={(e) => {
                            if (e.target.files.length > 0) {
                                setServerFile(e.target.files[0]);
                                setProgress(0);
                            }
                        }} className={"formColOneRowFlexInput " + (errors.server_file?.type)} type="file" accept="video/*" />
                    </div>
                    {isServerFileLoading && <div>
                        <p className="text-white">Загрузка файла</p>
                        <div className="row">
                            <div className="col-11">
                                <div className="progress mb-4">
                                    <div className="progress-bar" role="progressbar" style={{ width: `${progress}%` }}></div>
                                </div>
                            </div>
                            <div className="col-1">
                                {progress == 100 && <i className="fa fa-check text-success"></i>}
                            </div>
                        </div>

                    </div>}
                    {isServerFileLoading && progress == 100 && <div>
                        <p className="text-white">Конвертация файла</p>
                        <div className="row">
                            <div className="col-11">
                                <div className="progress mb-4">
                                    <div className={`progress-bar ${isDone ? '' : 'progress-bar-striped progress-bar-animated'}`} role="progressbar" style={{ width: `${100}%` }}></div>
                                </div>
                            </div>
                            <div className="col-1">
                                {isDone && <i className="fa fa-check text-success"></i>}
                            </div>
                        </div>

                    </div>}
                    <div className="d-flex justify-content-end mb-4 d-none">
                        <button type="button" onClick={() => {
                            uploadToServer();
                        }}>{isServerFileLoading ? <i className="fa fa-spinner fa-2x rotating "></i> : "Загрузить"}</button>
                    </div>

                    {screenshots.length > 0 && <div className="">
                        <div className="admin-form">
                            <h5 className='text-white'>Кадры</h5>
                            <div className="row">
                                {screenshots.map((screenshot, i) => {
                                    return <div className="col-md-6 mb-4" key={i}>
                                        <a role="button" className={'d-block' + (selectedScreenshot == screenshot ? ' border border-3 border-success' : '')} onClick={(e) => {
                                            setSelectedScreenshot(screenshot);

                                            setValue("file", null);
                                            trigger('file');

                                            setPreviewImage(screenshot);
                                        }}>
                                            <img src={screenshot} className='w-100 d-block' />
                                        </a>
                                    </div>
                                })}
                            </div>

                        </div>
                    </div>}


                    <div className="formColRowFlexDiv d-none">
                        <p className="formColOneRowFlexLabel fs-12">Название файла m3u8:</p>
                        <input {...register("filenames", { required: false })} className={"formColOneRowFlexInput fs-12 cursor-pointer " + (errors.filenames?.type)} type="text" readOnly placeholder="Название файла на сервере 2160p" onClick={(e) => {
                            if (e.target.value) {
                                window.open(e.target.value, '_blank');
                            }
                        }} />
                    </div>
                </form>
            </div>
        </div >
    );
}