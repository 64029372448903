import React from 'react';
import { NavLink } from 'react-router-dom';
import { AuthContext } from '../../components/context';

export default function Sidebar() {
    const authContext = React.useContext(AuthContext);

    const auth = authContext.authState;

    console.log(auth.user);

    React.useEffect(async () => {

    }, []);

    return (
        <div className="sidebar">
            <ul>
                <li>
                    <NavLink exact to="/settings" className="fs-18">Персоны</NavLink>
                </li>
                <li>
                    <NavLink exact to="/transaction" className="fs-18">Чеки</NavLink>
                </li>
                <li>
                    <NavLink exact to="/settings/genre" className="fs-18">Жанры</NavLink>
                </li>
                {/* <li>
                    <NavLink exact to="/settings/category" className="fs-18">Категории</NavLink>
                </li>
                <li>
                    <NavLink exact to="/settings/playlist" className="fs-18">Плейлисты</NavLink>
                </li> */}
                <li>
                    <NavLink exact to="/settings/country" className="fs-18">Страны</NavLink>
                </li>
                <li>
                    <NavLink exact to="/settings/subscription" className="fs-18">Подписки</NavLink>
                </li>
                <li>
                    <NavLink exact to="/settings/promocode" className="fs-18">Промокоды</NavLink>
                </li>
                <li>
                    <NavLink exact to="/settings/admin" className="fs-18">Доспут к админке</NavLink>
                </li>
                <li>
                    <NavLink exact to="/settings/password" className="fs-18">Изменить пароль</NavLink>
                </li>
                <li>
                    <NavLink exact to="/users" className="fs-18">Пользователи</NavLink>
                </li>
                <li>
                    <NavLink exact to="/active-subscriptions" className="fs-18">Активные подписки</NavLink>
                </li>
                <li>
                    <NavLink exact to="/settings/advert" className="fs-18">Реклама</NavLink>
                </li>
                <li>
                    <NavLink exact to="/settings/organization" className="fs-18">Организации</NavLink>
                </li>
                <li>
                    <NavLink exact to="/settings/partner" className="fs-18">Партнеры</NavLink>
                </li>
                <li>
                    <NavLink exact to="/settings/render" className="fs-18">Рендер</NavLink>
                </li>
                {auth.user?.username == "aliuzpro" && <li>
                    <NavLink exact to="/settings/uzrap-render" className="fs-18">UzRAP Рендер</NavLink>
                </li>}
            </ul>
        </div>
    );
}