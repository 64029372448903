import { useState, useEffect } from "react";

import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';

import { Line } from 'react-chartjs-2';

import faker from 'faker';

import { get } from '../../utils/request';

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
);

export const options = {
    responsive: true,
    plugins: {
        legend: {
            display: true,
            position: 'bottom',
            align: "left",
            fullSize: false,
            maxWidth: 20,
            labels: {
                padding: 5,
                font: {
                    size: 10
                }
            }
        },
    },
    animations: {
        tension: {
            duration: 2000,
            easing: 'linear',
            from: 0.3,
            to: 0,
            loop: true
        }
    },
    // layout: {
    //     padding: 20
    // }

};

export default function MediaViews() {
    const [data, setData] = useState(null);
    const [count, setCount] = useState(0);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const fetchData = async () => {

            let res = await get(`chart/media-views`);

            let labels = [];

            for (const model of res.models) {
                if (labels.indexOf(model.label) == -1)
                    labels.push(model.label);
            }

            let clips = [];

            for (const label of labels) {
                let c = res.models.find(m => m.label == label && m.type == 1);
                clips.push(c ? c.value : 0);
            }

            let films = [];

            for (const label of labels) {
                let c = res.models.find(m => m.label == label && m.type == 2);
                films.push(c ? c.value : 0);
            }

            let concerts = [];

            for (const label of labels) {
                let c = res.models.find(m => m.label == label && m.type == 4);
                concerts.push(c ? c.value : 0);
            }

            let humor = [];

            for (const label of labels) {
                let c = res.models.find(m => m.label == label && m.type == 5);
                humor.push(c ? c.value : 0);
            }

            let telenovellas = [];

            for (const label of labels) {
                let c = res.models.find(m => m.label == label && m.type == 6);
                telenovellas.push(c ? c.value : 0);
            }

            setData({
                labels: labels.map(l => l.split("-").reverse().join(".")),
                datasets: [
                    {
                        label: 'Клипы',
                        data: clips,
                        borderColor: 'rgb(255,13,61)',
                        backgroundColor: 'rgba(255,13,61, 0.5)',
                    },
                    {
                        label: 'Фильмы',
                        data: films,
                        borderColor: 'rgb(225,45,111)',
                        backgroundColor: 'rgba(225,45,111, 0.5)',
                    },
                    {
                        label: 'Юмор',
                        data: humor,
                        borderColor: 'rgb(49,157,181)',
                        backgroundColor: 'rgba(49,157,181, 0.5)',
                    },
                    {
                        label: 'Концерт',
                        data: concerts,
                        borderColor: 'rgb(250,176,37)',
                        backgroundColor: 'rgba(250,176,37, 0.5)',
                    },
                    {
                        label: 'Теленовелла',
                        data: telenovellas,
                        borderColor: 'rgb(55,169,10)',
                        backgroundColor: 'rgba(55,169,10, 0.5)',
                    },
                ]
            });

            setCount(res.count);

            setIsLoading(false);
        }

        fetchData();
    }, []);

    if (!data) return <h6 className="text-white">
        Количество просмотров
    </h6>;

    return (
        <>
            <h6 className="text-white mb-4">
                Количество просмотров
            </h6>

            <Line options={options} data={data} />
        </>
    );
}