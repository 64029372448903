import React from 'react';
import { NavLink } from 'react-router-dom';
import MediaViews from '../components/charts/MediaViews';
import MusicListens from '../components/charts/MusicListens';
import RegisterUsers from '../components/charts/RegisterUsers';

export default function Home() {
    React.useEffect(() => {
        document.title = "Главная";
    }, []);

    return (
        <section className="categories py-70 bg-different">
            <div className="container-fluid">
                <div className="row justify-content-center">
                    <div className="col-md-10">
                        <div className="row">
                            {/* <div className="col-lg-2 col-md-4 col-sm-6 col-6 mb-4">
                                <NavLink className="category" to="/music">
                                    <img src="image/category-music.jpg" />
                                    <div
                                        className="title cover d-flex align-items-center align-content-center justify-content-center flex-wrap">
                                        <img src="image/category-music.svg" />
                                        <span className="w-100 text-center mt-3">Музыка</span>
                                    </div>
                                </NavLink>
                            </div>
                            <div className="col-lg-2 col-md-4 col-sm-6 col-6 mb-4">
                                <NavLink className="category" to="/album">
                                    <img src="image/albums.jpg" />
                                    <div
                                        className="title cover d-flex align-items-center align-content-center justify-content-center flex-wrap">
                                        <img src="image/category-music.svg" />
                                        <span className="w-100 text-center mt-3">Альбомы</span>
                                    </div>
                                </NavLink>
                            </div> */}
                            {/* <div className="col-lg-2 col-md-4 col-sm-6 col-6 mb-4">
                                <NavLink className="category" to="/clip">
                                    <img src="image/category-clip.jpg" />
                                    <div
                                        className="title cover d-flex align-items-center align-content-center justify-content-center flex-wrap">
                                        <img src="image/category-clip.svg" />
                                        <span className="w-100 text-center mt-3">Клипы</span>
                                    </div>
                                </NavLink>
                            </div> */}

                            <div className="col-lg-2 col-md-4 col-sm-6 col-6 mb-4">
                                <NavLink className="category" to="/concert">
                                    <img src="image/category-concert.jpg" />
                                    <div
                                        className="title cover d-flex align-items-center align-content-center justify-content-center flex-wrap">
                                        <img src="image/category-concert.svg" />
                                        <span className="w-100 text-center mt-3">Концерты</span>
                                    </div>
                                </NavLink>
                            </div>

                            <div className="col-lg-2 col-md-4 col-sm-6 col-6 mb-4">
                                <NavLink className="category" to="/film">
                                    <img src="image/category-film.jpg" />
                                    <div
                                        className="title cover d-flex align-items-center align-content-center justify-content-center flex-wrap">
                                        <img src="image/category-film.svg" />
                                        <span className="w-100 text-center mt-3">Фильмы</span>
                                    </div>
                                </NavLink>
                            </div>

                            <div className="col-lg-2 col-md-4 col-sm-6 col-6 mb-4">
                                <NavLink className="category" to="/telenovella">
                                    <img src="image/category-telenovella.jpg" />
                                    <div
                                        className="title cover d-flex align-items-center align-content-center justify-content-center flex-wrap">
                                        <img src="image/category-telenovella.svg" />
                                        <span className="w-100 text-center mt-3">Теленовелла</span>
                                    </div>
                                </NavLink>
                            </div>

                            <div className="col-lg-2 col-md-4 col-sm-6 col-6 mb-4">
                                <NavLink className="category" to="/serial">
                                    <img src="image/category-serial.jpg" />
                                    <div
                                        className="title cover d-flex align-items-center align-content-center justify-content-center flex-wrap">
                                        <img src="image/category-serial.svg" />
                                        <span className="w-100 text-center mt-3">Сериалы</span>
                                    </div>
                                </NavLink>
                            </div>

                            <div className="col-lg-2 col-md-4 col-sm-6 col-6 mb-4">
                                <NavLink className="category" to="/humor">
                                    <img src="image/category-humor.jpg" />
                                    <div
                                        className="title cover d-flex align-items-center align-content-center justify-content-center flex-wrap">
                                        <img src="image/category-humor.svg" />
                                        <span className="w-100 text-center mt-3">Юмор</span>
                                    </div>
                                </NavLink>
                            </div>
                            {/* <div className="col-lg-2 col-md-4 col-sm-6 col-6 mb-4">
                                <NavLink className="category" to="/settings">
                                    <img src="image/category-singers.jpg" />
                                    <div
                                        className="title cover d-flex align-items-center align-content-center justify-content-center flex-wrap">
                                        <img src="image/category-singers.svg" />
                                        <span className="w-100 text-center mt-3">Персоны</span>
                                    </div>
                                </NavLink>
                            </div> */}
                            <div className="col-lg-2 col-md-4 col-sm-6 col-6 mb-4">
                                <NavLink className="category" to="/settings">
                                    <img src="image/category-settings.jpg" />
                                    <div
                                        className="title cover d-flex align-items-center align-content-center justify-content-center flex-wrap">
                                        <img src="image/category-settings.png" />
                                        <span className="w-100 text-center mt-3">Настройки</span>
                                    </div>
                                </NavLink>
                            </div>
                            {/* <div className="col-lg-2 col-md-4 col-sm-6 col-6 mb-4">
                                <NavLink className="category" to="/users">
                                    <img src="image/category-users.jpg" />
                                    <div
                                        className="title cover d-flex align-items-center align-content-center justify-content-center flex-wrap">
                                        <img src="image/category-users.png" />
                                        <span className="w-100 text-center mt-3">Пользователи</span>
                                    </div>
                                </NavLink>
                            </div> */}
                            <div className="col-lg-2 col-md-4 col-sm-6 col-6 mb-4">
                                <NavLink className="category" to="/report">
                                    <img src="image/category-report.jpg" />
                                    <div
                                        className="title cover d-flex align-items-center align-content-center justify-content-center flex-wrap">
                                        <img src="image/category-report.png" />
                                        <span className="w-100 text-center mt-3">Отчет</span>
                                    </div>
                                </NavLink>
                            </div>
                            <div className="col-lg-2 col-md-4 col-sm-6 col-6 mb-4">
                                <NavLink className="category" to="/template">
                                    <img src="image/category-template.jpg" />
                                    <div
                                        className="title cover d-flex align-items-center align-content-center justify-content-center flex-wrap">
                                        <img src="image/category-template.png" />
                                        <span className="w-100 text-center mt-3">Черновик</span>
                                    </div>
                                </NavLink>
                            </div>
                            <div className="col-lg-2 col-md-4 col-sm-6 col-6 mb-4">
                                <NavLink className="category" to="/transaction">
                                    <img src="image/559a3bf2f0481292bedd7.jpg" />
                                    <div
                                        className="title cover d-flex align-items-center align-content-center justify-content-center flex-wrap">
                                        <img src="image/transaction.png" />
                                        <span className="w-100 text-center mt-3">Чеки</span>
                                    </div>
                                </NavLink>
                            </div>

                        </div>

                        <div className="row mt-4">
                            <div className="col-md-4">
                                <RegisterUsers />
                            </div>
                            <div className="col-md-4">
                                <MediaViews />
                            </div>
                            {/* <div className="col-md-4">
                                <MusicListens />
                            </div> */}
                        </div>

                    </div>
                </div>
            </div>
        </section>
    );
}